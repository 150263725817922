.c-social {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$o-xs);

    &-item {
        flex: 1;
        padding: $g-xs;
        margin: 0 $g-xs;
    }

    &-link {
        display: inline-block;
        position: relative;

        padding: $o-sm $g;
        background: $gray-200;
        text-align: center;
        width: 100%;
        
        font: {
            weight: bold;
        };
        @extend .u-fz-20;

        overflow: hidden;

        .t-gray & {
            background: $white;
        }
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
    
            transform: translate(-50%, -50%);
    
            height: 75%;
            transition: $transition;
        }

        &:hover {
            background-color: $primary;
            color: $white;

            img {
                transform: translate(-50%, -50%) scale(1.5);
                filter: brightness(0) invert(1);
            }
        }
    }

}

@include media-breakpoint-up(xl) {
    .c-social {
        &-link {
            padding: $g-sm $g;
        }
    }
}