.c-blog {
    @extend .u-padding;

    .h1 {
        font-weight: 600;
    }

    &:not(.home-blog) {
        padding-top: $g;

        .btn {
            display: flex;
            align-items: center;
            margin: $g-sm auto 0;
        }
    }

    &-list {
        @extend .u-list;

        @include media-breakpoint-down(sm) {
            margin-left: -$g-sm;
            margin-right: -$g-sm;
        }
        
        @include media-breakpoint-up(md) {
            margin-left: -$o;
            margin-right: -$o;
        }
    }

    &-item {
        height: 100%;
    }

    &-link {
        display: block;
        position: relative;
        padding: $g-sm;
        height: 100%;
        color: $gray-900;

        transition: $transition;
        z-index: 1;

        &:hover {
            @extend .t-red;
            .c-news-date {
                opacity: .75;
            }
        }

        @include media-breakpoint-up(md) {
            padding: $g-sm $o;
        }

        @include media-breakpoint-up(lg) {
            padding: $o;
        }
    }

    &-img {
        position: relative;
        margin-bottom: $o;

        &::before {
            content: '';
            display: block;
            padding-bottom: 26.054%;

            @include media-breakpoint-down(md) {
                padding-bottom: 40%;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-date {
        display: inline-block;
        margin-bottom: $o-sm;
        font: {
            size: 1.6rem;
            weight: 600;
        };
        color: $gray-500;
    }

    &-title {
        font: {
            size: 2.2rem;
            weight: 600;
        };
        margin-bottom: $o-sm;
    }

    &-description {
        font-size: 1.6rem;
        color: $gray-500;
    }

    &-slider {
        @extend .u-padding;

        .title {
            @extend .h1;
            font-weight: 600;
            margin-bottom: $g-sm;
        }
    
        .swiper {
            &-container {
                @extend .u-full-screen-slider;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: $g;

                @include media-breakpoint-up(desktop) {
                    padding-right: calc((100vw - 1660px) / 2);
                    padding-left: calc((100vw - 1660px) / 2);
                }
            }
    
            &-slide {
                height: auto;
    
                .c-news {
                    height: 100%;
                }
            }
        }
    }
}