.c-advantages {
    @extend .u-padding;
    overflow: hidden;

    &-list {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }

        @include media-breakpoint-only(md) {
            justify-content: center;
            margin: 0 (-$o-xs);
        }
    }

    &-head {
        display: flex;
        align-items: center;
        margin-bottom: $g-sm;
    }

    &-title {
        display: inline-block;
        font-size: 2.2rem;
        font-weight: 600;
    }
    
    &-info {
        color: $gray-500;
    }

    &-item {
        position: relative;

        @include media-breakpoint-down(md) {
            max-width: 600px;

            &:not(:last-child) {
                margin-bottom: 50px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 33.333334%;

            &:not(:nth-child(n+3)) {
                padding-right: $g;
            }
        }

        @include media-breakpoint-up(xl) {
            &:not(:nth-child(n+3)) {
                padding-right: $o-lg;
            }
        }
    }

    &-icon {
        flex-shrink: 0;
        margin-right: $g-sm;
    }

}