.c-footer {

    &-inner {
        padding: $g 0 0;
    }

    .btn {
        margin-left: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-top: $o;
        }
    }

    &-logo {
        a {
            display: block;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

            img {
                width: 219px;
            }
        }
    }

    &-contacts {
        display: flex;
        width: 100%;
        padding: $o 0;

        &__block {
            &:last-child {
                margin-left: auto;
            }

            .subtitle {
                font: {
                    size: 1.2rem;
                    weight: 600;
                };
                color: $gray-500;
            }

            .phone {
                display: inline-block;

                font: {
                    size: 1.6rem;
                    weight: 600;
                };

                &:nth-child(5) {
                    transform: translateY(-$g-xs);
                }

                &:nth-child(7) {
                    transform: translateY(-$o-xs);
                }
            }
        }
    }

    &-nav {
        width: 100%;
        padding-top: $o-sm;
        margin-bottom: $o;
        border-top: 1px solid $gray-200;    

        .c-nav {
            display: block;
            margin: 0 (-$g-sm);

            &-item {
                margin: 0;
                padding: 0;

                @include media-breakpoint-up(xl) {
                    &:last-child {
                        text-align: right;
                    }


                    &:nth-child(2),
                    &:nth-child(3) {
                        text-align: center;
                    }
                }
            }

            &-link {
                padding: $o-xs $g-sm;
                font-weight: 600;
                font-size: 1.6rem;

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }

                @include media-breakpoint-down(xl) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    &-bottom {
        width: 100%;
        order: 4;
        display: flex;
        justify-content: space-between;
        margin-bottom: $o-sm;
        font: {
            size: 1.4rem;
            weight: 600;
        };

        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            white-space: nowrap;
            border: 0;
        }

        .copyright {
            color: $gray-500;
        }

        .c-ideil {
            svg {
                margin-top: -3.7px;
            }
        }
    }
}

@include media-breakpoint-only(md) {
    .c-footer {

        &-logo {
            width: 50%;
        }

        .btn {
            margin-left: $g-sm;
        }

        &-contacts__block {
            width: 50%;

            &:last-child {
                padding-left: $g-sm;
                margin-left: o;
            }
        }

    }
    
}

@include media-breakpoint-up(md) {
    .c-footer {
        &-inner {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        &-nav {
            .c-nav {
                display: flex;
                flex-wrap: wrap;
        
                &-item {
                    width: 50%;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .c-footer {
        &-logo {
            width: auto;
        }

        .btn {
            order: 2;
            margin-left: 0;
        }

        &-contacts {
            width: auto;
            margin: -13px $o-lg $g-sm auto;
            padding: 0;

            &__block:last-child {
                margin-left: $o-lg;
            }
        }

        &-nav {
            order: 3;

            .c-nav-item {
                width: 25%;
            }
        }

    }
}

@include media-breakpoint-up(xl) {
    .c-footer {
        &-logo a img {
            width: 250px;
        }
        
        &-contacts {
            margin-right: $o * 4;
        }
    }
}