.c-seo {
    & > * {
        margin-bottom: $o-sm;
    }

    b {
        display: inline-block;
        
        .post-news & {
            @extend .u-fz-24;
        }
    }

    ol,ul {
        @extend .u-list;
        margin-bottom: $g-sm;
 
        li p {
            position: relative;

            &:not(:last-child) {
                margin-bottom: $o-xs;
            }

            &::before {
                display: inline-block;
            }
        }
    }

    ul {
        li p {
            &::before {
                content: '';

                width: $o-xs;
                height: $o-xs;

                margin-right: $o-xs;
            
                background-color: $primary;
            }
        }
    }

    ol {
        counter-reset: list;

        li p {
            padding-left: $g-sm;

            &::before {
                content: counter(list) ". ";
                counter-increment: list;

                position: absolute;
                top: $g-xs;
                left: 0;

                color: $primary;

                position: absolute;
                top: 0;
                
                font-size: inherit;
                font-weight: bold;
            }
        }
    }

    img {
        display: block;
    }
}

@include media-breakpoint-up(md) {
    .c-seo {
        & > * {
            margin-bottom: $g-sm;
            font-size: 1.6rem;
        }

        ol,ul {
            li {
                &:not(:last-child) {
                    margin-bottom: $o;
                }
            }
        }
    }
}