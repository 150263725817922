.c-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    border-bottom: 1px solid $gray-100;
    background: $white;
    
    transition: 0s;
    z-index: 2000;
    
    .has-scroll & {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    }
    
    body.modal-open & {
        z-index: 20;
        transition: 0s;
        padding-right: $o-sm;
    }
    
    &-inner {
        padding: $o-sm 0;
        display: flex;
        align-items: center;

        .c-logo {

            a {

                img {
                    width: 96px;

                    @include media-breakpoint-up(lg)  {
                        width: 120px;
                    }

                    @include media-breakpoint-up(laptop)  {
                        width: 150px;
                    }
                }
            }
        }
    }

    &-phones {
        &__link {
            padding: $g-xs;

            font: {
                weight: 600;
            };

            img {
                margin-top: -2px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .has-open-nav & {
            &::before {
                content: '';

                position: fixed;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                background: rgba(240, 240, 246, 0.59);
                opacity: 1;
                z-index: 1;
            }
        }

        &-inner {
            padding: $g-xs 0;
            position: relative;
    
            .c-logo {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -55%);
            }
        }

        &-nav {
            display: flex;
            flex-direction: column;

            position: fixed;
            top: 0;
            left: 0;

            width: 280px;

            background: $white;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
            padding: $o-xs;
            padding-top: 0;

            height: 100vh;
            min-height: 100%;
            overflow-y: auto;
            visibility: hidden;

            transform: translateX(-250px);
            transition: $transition;

            z-index: 10;

            .has-open-nav & {
                transform: translateX(0);
                visibility: visible;
            }

            &--md {
                border-bottom: 1px solid $gray-200;
                display: flex;
                align-items: center;

                margin-left: -$o-xs;
                margin-right: -$o-xs;
                padding: 0 $o-xs;

                .c-logo {
                    transform: translate(0);
                    position: static;
                    margin-left: auto;
                }

                .c-burger {
                    margin-left: -$o-xs;
                }
            }

            .c-nav {
                margin-top: $o-xs;
                margin-bottom: $o;
            }
        }

        &-phones {
            margin-top: auto;

            &__link {
                display: block;
                
                &:not(:last-child) {
                    margin-bottom: $g-xs;

                    img {
                        max-width: $g-sm;
                    }
                }
                
                span {
                    padding-left: 21px;
                }

                img + span {
                    padding-left: 0;
                }
            }
        }

        .btn {
            margin-top: $o;
        }
    }
    
    @include media-breakpoint-up(xl) {
        &-inner {
            padding: $o-sm 0;

            .c-logo {
                transform: translateY(-$g-xs);
            }
        }

        &-nav {
            display: flex;
            align-items: center;

            margin-left: auto;

            &--md {
                display: none;
            }

            .btn {
                margin-left: $o-sm;
            }
        }

        &-phones {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            &__link {
                &:not(:last-child) {
                    margin-right: $o-sm;
                }
            }
        }
    }
    
    @include media-breakpoint-up(hd) {
        &-phones {
            margin: 0 $o;
        }
    }
}
