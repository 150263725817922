.c-burger {
    display: inline-block;
    padding: $o-xs;
    cursor: pointer;
    margin-left: -$o-xs;

    span {
        display: block;
        
        width: 25px;
        height: 3px;

        margin: 3px 0;
        background-color: $primary;
    }

    &--close {
        width: 48px;
        height: 48px;

        position: relative;

        transform-origin: center;
        transition: $transition;

        span {
            position: absolute;
            top: 50%;
            left: 50%;

            margin: 0;

            &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:last-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &:hover {
            transform: rotate(90deg);
        }
    }
}

@include media-breakpoint-up(xl) {
    .c-burger {
        display: none;
    }
}