.c-error {
    text-align: center;
    padding: $g-lg 0;

    &-number {
        color: $primary;
        @include fluidFontSize(70px, 170px, 480px, 1660px, 90px);
    }

    &-title {
        @extend .h1;
        margin-bottom: $o;
    }
}