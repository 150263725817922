.c-order {
    text-align: center;
    padding: 50px 0;

    &-title {
       @extend .h2;
       margin: 0;
    }

    p {
        margin: $g-sm 0;
        font: {
            size: 1.8rem;
            weight: 600;
        };
        line-height: 1.2;
        color: $gray-500;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px $o;

        &:not(.t-gray) {
            border-left: 2px solid $gray-100;
        }
        
        p {
            margin: $o 0;
        }

        .home-services & {
            grid-area: order;
            
            .btn {
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(desktop) {
        .home-services & {
            padding-right: calc((100vw / 2) - (1660px / 2) + 20px);
        }
    }
}