.c-dropdown {
    &-list {
        @extend .u-list;
    }
    
    &-link {
        display: flex;
        padding: $g-xs;
        
        font: {
            size: 1.6rem;
            weight: 600;
        };

        color: $gray-500;

        &:not(.is-active):hover {
            color: $gray-900;
        }

        &.is-active {
            color: $primary;
        }
    }
    
    @include media-breakpoint-down(xl) {
        padding: 0;
        background: $white !important;

        &-link {
            font: {
                size: 1.4rem;
            };
        }
    }
    
    @include media-breakpoint-down(lg) {
        padding: $o-xs 0 $o-xs;
    }
    
    @include media-breakpoint-up(xl) {
        position: absolute;
        top: 54px;
        left: -24px;
        width: 300px;

        padding: $o-xs 0;

        
        background-color: $white;
        border-bottom: 1px solid $gray-100;
        box-shadow: 0px 5px 10px rgba(80, 43, 43, 0.1);
        
        opacity: 0;
        visibility: hidden;
        overflow: hidden;

        transform: translateY(-10%);
        transition: $transition;

        &:hover {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            .c-nav-link.has-dropdown::after {
                transform: translateY(-50%) rotate(-90deg);
            }
        }

        &-list {
            position: relative;
            width: 100%;
            margin: 0 auto;
        }
        
        &-link {
            padding: $o-xs $g-sm;
        }
    }
}