// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: flex;
    align-items: center;

    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($o-sm, $g-sm, 1.6rem, 1.2, 0);
    @include transition($btn-transition);

    // Share hover and focus styles
    @include hover-focus {
    text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: none;
        box-shadow: $btn-focus-box-shadow;
        color: $white;
    }

    &-light,
    &-secondary {
        &:focus,
        &.focus {
            color: $primary;
        }
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
    }

    // Opinionated: add "hand" cursor to non-disabled .btn elements
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    img {
        margin-right: $o-xs;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
