.services {
    &-top {
        background: transparent !important;
        
        .info {
            @extend .u-padding;

            p {
                color: $black;
                opacity: .5;
            }
        }

        @include media-breakpoint-down(sm) {
            .img {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            position: relative;

            &-inner {
                display: flex;
                justify-content: flex-start;
            }

            &::before {
                content: '';

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                
                display: block;
                height: 100%;
                width: 30%;

                z-index: -1;

                @include media-breakpoint-up(desktop) {
                    right: 0;
                }
            }

            @each $i, $color in $colors {
                &.t-#{$i}::before {
                    background: $color;
                }
            }

            &.t-green::before {
                background: radial-gradient(61.88% 73.31% at 14.72% 46.64%, #67D3CC 0%, #4EA9A3 100%);
            }

            .info {
                width: 60%;

                p {
                    font-size: 1.8rem;
                }
            }

            .img {
                width: 40%;
                padding-left: 5%;

                display: flex;
                align-items: center;
            }
        }

        @include media-breakpoint-up(lg) {
            .info {
                padding-left: 4%;

                p {
                    font-size: 2rem;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .info {
                width: 55%;
            }

            .img {
                width: 45%;
            }
        }

        @include media-breakpoint-up(hd) {
            .info {
                padding-left: 7%;
            }
        }
    }

    &-brands {
        padding: $o 0;
        border-top: 1px solid $gray-100;

        .swiper {
            &-wrapper {
                align-items: center;
                transition-timing-function: linear;
            }

            &-slide {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-height: $o;

                    @include media-breakpoint-up(lg) {
                        max-height: $o-lg;
                    }
                }
            }
        }
    }

    &-price {
        @extend .u-padding;

        &__head {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $g-sm;

            .h1 {
                margin-right: $o;
            }
        }

        &__block {
            background: $white;
            padding: $o-sm $g-sm;

            &:not(:last-child) {
                margin-bottom: $o-sm;
            }
        }

        .price {
            &-subtitle {
                display: inline-block;
                @extend .u-fz-22;
                text-transform: uppercase;
                margin-bottom: $o-sm;
                margin-top: $o-xs;
            }

            &-list {
                @extend .u-list;
            }
            
            &-item {
                display: flex;
                justify-content: space-between;
                
                @extend .u-fz-18;
                color: $gray-500;
                font-weight: 500;

                padding: $o-xs $o-sm;
                margin-left: -$o-sm;
                margin-right: -$o-sm;

                transition: $transition;

                .price {
                    flex-shrink: 0;
                    font-weight: 600;
                    font-variant-numeric: tabular-nums;
                    margin-left: $o;
                }

                &:hover {
                    cursor: pointer;
                    color: $gray-900;
                    background: $gray-200;
                }
            }
        }

        @include media-breakpoint-up(md) {
            &__block {
                padding: $o $g;
            }

            .price {
                &-subtitle {
                    max-width: 80%;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            padding-left: 4%;
            padding-right: 4%;

            .price-subtitle {
                max-width: 60%;
            }
        }

        @include media-breakpoint-up(hd) {
            padding-left: 7%;
            padding-right: 7%;
        }
        
    }
}
