.u-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

$paddings: (
    xs:(
        padding-top:  $o-lg,
        padding-bottom:  $o-lg
    ),
    md:(
        padding-top: $g-lg,
        padding-bottom: $g-lg
    )
);

@each $media, $pd in $paddings {
    @each $pd-b, $pd-t in $pd {
        @include media-breakpoint-up(#{$media}) {
            .u-padding {
                #{$pd-b}: #{$pd-t};
            }
        }
    }
};

.u-fz-18 {
    @include fluidFontSize(16px, 18px, 480px, 1200px, 16px);
}

.u-fz-20 {
    @include fluidFontSize(14px, 20px, 480px, 1660px, 16px);
}

.u-fz-22 {
    @include fluidFontSize(18px, 22px, 480px, 1660px, 18px);
}

.u-fz-24 {
    @include fluidFontSize(20px, 24px, 480px, 1660px, 20px);
}

.u-fz-30 {
    @include fluidFontSize(18px, 30px, 480px, 1660px, 22px);
}

.u-fz-36 {
    @include fluidFontSize(24px, 36px, 480px, 1660px, 30px);
}

@keyframes spinner {
    to {
        transform:rotate(360deg)
    }
}

.u-spinner {
    display: inline-block;
    
    width: $g-sm;
    height: $g-sm;

    border: 3px solid $primary;
    border-radius: 50%;
    border-right-color: transparent;

    margin-left: $o-xs;

    animation: spinner .75s linear infinite;
}

.u-full-screen-slider {
    @include media-breakpoint-down(laptop) {
        padding-right: $o;
        padding-left: $o;

        margin-right: -$o;
        margin-left: -$o;
    }

    @include media-breakpoint-down(md) {
        padding-left: $o;
        padding-right: $o;

        margin-right: -$o;
        margin-left: -$o;
    }
    
    @include media-breakpoint-down(sm) {
        padding-left: $g-sm;
        padding-right: $g-sm;

        margin-right: -$g-sm;
        margin-left: -$g-sm;
    }

    @include media-breakpoint-up(desktop) {
        padding-right: calc((100vw - 1620px) / 2);
        padding-left: calc((100vw - 1620px) / 2);

        margin-right: calc((100vw - 1620px) / (-2));
        margin-left: calc((100vw - 1620px) / (-2));
    }
}