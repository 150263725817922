.c-nav {
    @extend .u-list;
    
    &-link {
        display: inline-block;
        flex-shrink: 0;

        padding: $g-xs;
        font: {
            size: 1.6rem;
            weight: 600;
        };

        &:not(.has-dropdown).is-active {
            color: $primary;
        }

        img {
            margin-right: $g-xs;
        }
    }
}

@include media-breakpoint-down(lg) {
    .c-nav {
        &-item {
            padding: $g-xs;
            margin-left: -$g-xs;
        }

        &-link {
            display: block;

            font: {
                size: 2rem;
                weight: 700;
            };

            &.has-dropdown {
                color: $gray-900 !important;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .c-nav {
        display: flex;
        margin-left: auto;

        &-item {
            margin: 0 1.1vw;
            position: relative;
        }

        &-link {
            &.has-dropdown {
                padding-right: $o-sm;

                &::before {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    right: 0;
                    transform: translateX(-50%);

                    display: inline-block;
                    height: 25px;

                    width: 150%;
                    background: transparent;
                    pointer-events: none;
                }

                &::after {
                    content: '';

                    position: absolute;
                    right: 0;
                    top: 50%;

                    transform: translateY(-50%) rotate(90deg);
                    transform-origin: center;
                    
                    display: inline-block;
                    width: $o-xs;
                    height: $o-xs;

                    background: url('../img/svg/arrow-angle.svg') center no-repeat;
                    background-size: contain;
                    filter: brightness(0%);
                    
                    transition: $transition;
                }
                
                &:hover {
                    &::before {
                        pointer-events: all;
                    }
                    
                    &::after {
                        transform: translateY(-50%) rotate(-90deg);
                        filter: none;
                    }
                    & + .c-dropdown {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(hd) {
    .c-nav {
        &-item {
            margin: 0 $g-sm;
        }
    }
}

@include media-breakpoint-up(laptop) {
    .c-nav {
        &-item {
            margin: 0 $o;
        }
    }
}