.btn {
    display: inline-flex;
	align-items: center;
	justify-content: center;
    
    text-decoration: none;
    
	border: none;

	outline: none;
	box-shadow: none;

    vertical-align: middle;
	-webkit-appearance: none;
	transition: $transition;

    user-select: none;
    padding: $o-sm $o;

    img {
        transition: $transition;
    }
    
    &:active,
	&:focus,
	&:hover {
		outline: none;
		box-shadow: none;
        text-decoration: none;
    }
    
    &-primary {
        img {
            filter: brightness(0) invert(1);
            margin-left: $o-xs;
            margin-right: 0;
            height: 10px;
        }

        &:active,
        &:focus,
        &:hover {
            color: $white !important;

            img {
                transform: translateX($g-xs);
            }
        }
    }

    &-light {
        color: $gray-900;    
        background-color: $gray-100;

        &:hover,
        &:focus,
        &:active {
            color: $black !important;
        }

        img {
            transform-origin: center;
            transition: $transition;
        }
    
        &:hover,
        &:focus,
        &:active {
            img {
                transform: rotate(90deg);
            }
        }

        .c-blog & {
            img {
                filter: brightness(0%);
                margin-left: $o-xs;
                margin-right: 0;
                height: 10px;
            }
    
            &:active,
            &:focus,
            &:hover {
                img {
                    transform: translateX($g-xs);
                }
            }
        }
    }

    &-secondary {
        color: $primary;

        &:hover,
        &:focus,
        &:active {
            color: $white;
            background: $primary;
        }
    }

    &-dark {
        &:hover,
        &:focus,
        &:active {
            color: $white !important;
        }
    }

    &-load {
        display: none;

        &.is-loading {
            display: block;
        }
    }

    &-close {
        position: absolute;
        top: $o-xs;
        right: $o-xs;
        
        padding: $g-sm;
        background: transparent;

        &:hover {
            transform: rotate(90deg);
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;

            display: inline-block;
            width: 25px;
            height: 3px;

            background-color: $primary;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
} 

.btn-hidden {
    visibility: hidden !important;
}