@import "_post-services";

.post {
    &-img {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-bottom: 21.3%;

            @include media-breakpoint-down(sm) {
                padding-bottom: 40%;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-blog {
        @extend .u-padding;

        @include media-breakpoint-up(lg) {
            .c-seo {
                max-width: 1000px;
                padding-left: 4%;
            }
        }
        
        @include media-breakpoint-up(laptop) {
            .c-seo {
                padding-left: 7%;
            }
        }
    }
}