.post-services {
    @extend .u-padding;

    &__price {
        background-color: $primary;
        padding: $o;
        color: $white;
        
        .title {
            font-size: 2.4rem;
            position: relative;

            padding-bottom: $o-xs;
            margin-bottom: $o;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;

                display: inline-block;
                
                width: 30%;
                height: 2px;

                background: $white;
            }
        }
    }

    &__aside {
        @include media-breakpoint-down(sm) {
            margin-bottom: $g;
        }
    }
}

.price-list {
    &__item {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: $o;
        }

        p {
            margin-bottom: 0;
            width: 60%;
            line-height: 1.3;
        }

        b {
            flex-shrink: 0;
            text-align: right;
            font-variant-numeric: tabular-nums;
            margin-left: $g-sm;
        }
    }   
}

@include media-breakpoint-up(md) {
    .post-services {
        display: flex;

        &__aside {
            width: 40%;
        }

        &__price {
            position: sticky;
            top: $g-lg;
        }
        
        .c-seo {
            width: 60%;
            padding-left: $o-lg;
        }
    }
}

@include media-breakpoint-up(xl) {
    .post-services {

        &__aside {
            width: 30%;
        }

        &__price {
            top: 120px;
        }
        
        .c-seo {
            width: 70%;
        }
    }
}