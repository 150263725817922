//# Fonts
//** Font weight hint
// 100 – thin
// 200 – extra light
// 300 – light
// 400 – regular (normal)
// 500 – medium
// 600 – semibold
// 700 – bold
// 800 – extrabold
// 900 – black

@include font-face('Montserrat', '../fonts/Montserrat/Montserrat-Medium', 500);
@include font-face('Montserrat', '../fonts/Montserrat/Montserrat-SemiBold', 600);
@include font-face('Montserrat', '../fonts/Montserrat/Montserrat-Bold', 700);