
.home {
    &-services {

        @include media-breakpoint-only(md) {
            display: flex;
            flex-wrap: wrap;
        }
    
        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-areas: "lg order" "lg diagnostics" "md data";
        }
    }

    &-blog {
        .title {
            font-size: 5.4rem;
            font-weight: 600;
        }

        .btn-wrap {
            padding-top: $g-sm;
            text-align: center;
        }

        @include media-breakpoint-between(md, lg) {
            .grid-item:nth-child(2) ~ *  {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(laptop) {
    .home-repair {
        &-info {
            padding: $o-lg $g-lg $o-sm;
        }

        &-order {
            padding-top: $o-lg;
        }
    }
}