.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: $o-xs;
    list-style: none;
    margin: 0 (-$o-xs) ;
}

.breadcrumb-item {
    @extend .u-fz-18;

    a {
        color: $gray-500;

        &:hover {
        color: $primary;
        }
    }
    
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding;

        &::before {
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-right: $breadcrumb-item-padding;
        color: $breadcrumb-divider-color;
        content: $breadcrumb-divider;
        }
    }

    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }
    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

    &.active {
        color: $breadcrumb-active-color;
    }
}
