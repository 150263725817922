.c-feedback {
    .h1 {
        margin-bottom: $g;

        @include media-breakpoint-up(md) {
            margin-bottom: $o-lg;
        }
    }

    &-item {
        position: relative;
        background: rgba($white, .5);

        padding: $o $o $g;
        margin: 0;

        height: 100%;
        z-index: 1;

        .icon {
            position: absolute;
            top: 0;
            left: $g-xs;
        }
    }

    .swiper {
        &-container {
            padding-bottom: $g;
            @extend .u-full-screen-slider;
        }

        &-slide {
            padding: 0 8px;
            height: auto;
        }
    }

    &-img {
        position: relative;
        width: 65px;

        border-radius: 50%;
        overflow: hidden;

        flex-shrink: 0;
        margin-bottom: $o-xs;

        &::before {
            content: '';
            display: block;
            padding-bottom: 65px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .name {
        display: block;
        @extend .u-fz-24;
        font-weight: 600;
        margin-bottom: $o-sm;
    }

    &-text {
        font: {
            size: 1.6rem;
            weight: 500;
        };
        color: $gray-500;
    }

}
