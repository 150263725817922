@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    font-size: $f-min;
  
    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback;
        }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);  
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}

@mixin font-face($family, $filepath, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		font-display: swap;
		src: local($family),
			url('#{$filepath}.woff') format('woff'),
			url('#{$filepath}.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}