*:focus {
    outline: none !important;
}

html {
    font-size: 10px;
}

body {
    background-color: $body-bg;
    fill: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $gray-900;

    font-size: 1.6rem;
    min-height: 100vh;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main {
    flex-grow: 1;
}

img {
    max-width: 100%;
    height: auto;
    user-select: none;
}

button,
.btn,
a {
    transition:
        color $transition,
        background $transition,
        border-color $transition,
        fill $transition,
        opacity $transition,
        transform $transition;
    // outline: none !important;
}

a {
    color: $gray-900; 
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}

.title {
    display: block;
    font-weight: bold;
}

.t-red {
    background: $primary;

    & > *, a {
        color: $white;
    }

    a:hover {
        color: $black;
    }
}

// .lazy {
//     opacity: 0;
    
//     &.loaded,
//     &.swiper-lazy-loaded {
//         opacity: 1;
//     }
// }

@include media-breakpoint-down(lg) {
    html {
        &::before {
            transition: $transition;
            opacity: 0;
        }
    }

    body {
        padding-top: 50px;

        .has-open-nav & {
            overflow: hidden;
        }
    }
    
}

@include media-breakpoint-up(xl) {
    body {
        padding-top: $g-lg;
        font-size: 1.6rem;
    }
}

.schedule-modal {
    max-width: 400px;
    text-align: left;
    margin: $o auto 0;

    &__item {
        font-size: 1.8rem;
    
        &:not(:last-child) {
            margin-bottom: $g-xs;
        }
    }
}