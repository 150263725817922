@import "_services-page";
// @import "_services-work";

.c-services {
    padding: 50px $g-sm 0;
    position: relative;
    transition: $transition;
    overflow: hidden;
    text-align: center;

    .home-services & a {
        color: $white;

        &:hover {
            color: $gray-900;
        }
    }

    &-title {
        @extend .h2;
        
        .c-services--lg & {
            @extend .h1;
        }

        margin-bottom: $o;
    }

    &-list {
        @extend .u-list;

        @include media-breakpoint-down(sm) {
            max-width: 550px;
            margin:  0 auto ;
        }

        @include media-breakpoint-up(lg) {
            width: 60%;
        }
    }

    &-item {
        margin-bottom: $g-sm;
    }

    &-link {
        font-size: 1.6rem;
        display: inline-block;
    }

    &-img {
        transition: $transition;

        img {
            position: relative;
            transition: $transition;
            z-index: 1;
        }
    }

    @include media-breakpoint-down(sm) {

        &:nth-child(2) .c-services-img {
            margin-top: 0;
        }

        .btn-primary {
            position: relative;
            margin-bottom: -140px;
            z-index: 2;
        }

        &.t-green {
            background: radial-gradient(248.73% 59.54% at 70.56% 86.97%, #67D3CC 0%, #4EA9A3 100%);
        }

        &-img {
            margin-top: $g;
        }
    }

    @include media-breakpoint-only(md) {
        width: 50%;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px $o 0;

        &--lg,
        &--md {
            display: block;
            width: 100%;
            padding-bottom: 50px;
            text-align: left;

            .c-services {
                &-title {
                    width: 70%;
                    margin-bottom: $g;
                }

                &-list {
                    width: 50%;
                }
                
                &-img {
                    display: flex;
                    justify-content: flex-end;

                    position: absolute;
                    bottom: 0;
                    right: -1px;

                    height: 90%;
                    width: 45%;

                    picture {
                        display: flex;
                        justify-content: flex-end;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        &.t-green {
            background: radial-gradient(39.66% 55.63% at 71% 63.38%, #67D3CC 0%, #4EA9A3 100%);
        }

        .btn {
            margin-top: $o;
        }

        &-link {
            font-size: 1.8rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &--lg {
            grid-area: lg;
        }

        &--md {
            grid-area: md;
        }

        &:nth-child(4) {
            grid-area: data;
        }
        
        &:nth-child(3) {
            grid-area: diagnostics;
        }

        &:nth-child(5) {
            grid-area: md;
        }

        &.t-green {
            background: radial-gradient(39.66% 55.63% at 71% 63.38%, #67D3CC 0%, #4EA9A3 100%);
        }
    }

    @include media-breakpoint-up(desktop) {
        
        &--lg,
        &--md {
            padding-left: calc((100vw / 2) - (1660px / 2) + 20px);
        }
        
        &:not(.c-services--lg),
        &:not(.c-services--md) {
            padding-right: calc((100vw / 2) - (1660px / 2) + 20px);
        }
    }

}