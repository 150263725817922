$grid-2: (
    xs: 100%,
    sm: 50%
);

@each $media, $width in $grid-2 {
    @include media-breakpoint-up(#{$media}) {
        .grid-2 {
            .grid-item {
                display: flex;
                width: #{$width};
            }
        }
    }
};

$grid-3: (
    xs: 100%,
    md: 50%,
    xl: 33.33334%,
);

@each $media, $width in $grid-3 {
    @include media-breakpoint-up(#{$media}) {
        .grid-3 {
            .grid-item {
                display: flex;
                width: #{$width};
                text-align: left;
            }
        }
    }
};

.o-box {
    padding-left: $g-sm;
    padding-right: $g-sm;

    margin: 0 auto;
    max-width: 1660px;

    @include media-breakpoint-up(md) {
        padding-left: $o;
        padding-right: $o;
    }
}

.o-page-top {
    &__info {
        position: relative;
        @extend .u-fz-22;
        padding: $g 0;

        .h1 {
            font-weight: 600;
        }

        p {
            margin-bottom: 0;
            font-size: 1.8rem;
        }
    }

    @include media-breakpoint-down(md) {
        &__info {
            &::before {
                content: '';
                position: absolute;
                left: -$g-sm;
                right: -$g-sm;
                bottom: 0;
    
                display: inline-block;
                width: calc(100% + 40px);
                height: 1px;
                background: $gray-100;
            }
        }

        &__order .c-order {
            width: calc(100% + 40px);
            margin-left: -$g-sm;
            margin-right: -$g-sm;

            @include media-breakpoint-only(md) {
                width: calc(100% + 60px);
                margin-left: -$o;
                margin-right: -$o;
            }
        }
    }
}

.o-more-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    margin: 0 (-$o-xs);

    img {
        padding: $o-sm $o-xs;
    }
}

.v-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@include media-breakpoint-up(sm) {
    .grid-2 {
        display: flex;
        flex-wrap: wrap;
    }
}

@include media-breakpoint-up(md) {

    .grid-3 {
        display: flex;
        flex-wrap: wrap;
    }
}

@include media-breakpoint-up(lg) {
    
    .o-page-top {

        display: flex;
        margin-right: -$o;

        &__info {
            width: 65%;
            padding-left: 4%;
            padding-right: $o;
        }

        &__order {
            width: 35%;

            .c-order {
                width: 100%;
                height: 100%;
            }
        }
    }

    .o-more-img {
    
        margin: 0 (-$o-sm);
    
        img {
            padding: $o-sm;
        }
    }
}

@include media-breakpoint-up(laptop) {
    .o-page-top {
        &__info {
            padding-left: 7%;
        }
    }
}

@include media-breakpoint-up(desktop) {
    .o-page-top {
        margin-right: 0;

        &__info {
            padding-left: 7%;
        }
    }
}