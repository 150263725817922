.c-contacts {
    &-about {
        &__inner {
            padding-top: $g;
            padding-bottom: $g;
    
            @include media-breakpoint-up(md) {
                padding-top: $o-lg;
                padding-bottom: $o-lg
            }
    
            @include media-breakpoint-up(xl) {
                padding-top: $g-lg;
                padding-bottom: $g-lg;
            }
        }
    }

    &-info {
        @extend .u-fz-22;

        span {
            &:first-child {
                font-weight: 600;
            }

            &:not(:first-child) {
                font-weight: 500;
                color: $gray-500;
            }
        }
    }
}

.contacts {
    &-address {
        @extend .u-padding;

        & > * {
            line-height: 1.5;
        }

        &__way {
            margin-bottom: 0;

            .title {
                @extend .u-fz-24;
                margin-bottom: $o-xs;
            }

            p {
                margin-bottom: 0;
            }
        }
        
        &__schedule {
            color: $gray-600;
        }
    }

    &-callback {
        &__btn {
            position: relative;

            display: block;
            width: 100%;

            background: $white;
            color: $gray-900;
            border: none;
            
            padding: $g-sm;
            text-align: left;
            line-height: 1.5;

            cursor: pointer;

            &:hover {
                background-color: $primary;
                color: $white;

                img {
                    filter: brightness(0) invert(1);
                    opacity: .5;
                }
            }

            b {
                display: block;
                @extend .u-fz-30;
                position: relative;
                z-index: 1;
            }

            img {
                position: absolute;
                top: 50%;
                right: $g-sm;

                transform: translateY(-50%);

                max-height: calc(100% - 40px);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .contacts-callback {
        &__btn {
            margin: 0 auto;
            max-width: 400px;

            &:not(:last-child) {
                margin-bottom: $o-sm;
            }

        }
    }
}

@include media-breakpoint-down(sm) {
    .c-contacts {
        &-social {
            margin-top: $o;
        }
    }
}

@include media-breakpoint-up(sm) {
    .contacts {
        &-address {
            &__inner {
                display: flex;
                align-items: flex-end;
            }

            &__way {
                width: 50%;
            }

            &__schedule {
                padding-left: $o-sm;
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .c-contacts {
        &-about__inner {
            display: flex;
        }

        &-info {
            width: 66.6666%;
            padding-right: 2vw;
        }

        &-social {
            width: 33.333334%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .c-contacts {
        &-info {
            padding-right: 3vw;
        }
    }

    .contacts {
        &-address {
            &__inner {
                padding-left: 4%;
                padding-right: 4%;
            }

            &__schedule {
                padding-left: $g;
            }
        }

        &-callback {
            display: flex;
            justify-content: center;
            margin: 0 (-$o-sm);
    
            &__btn {
                width: 33.33334%;
                margin: 0 $o-sm;
            }
        }
    }
}

@include media-breakpoint-up(laptop) {
    .c-contacts {
        &-info {
            padding-right: $g-lg;
        }
    }

    .contacts {
        &-address__inner {
            padding-left: 7%;
            padding-right: 7%;
        }

        &-callback {
            margin: 0 (-$g-sm);
    
            &__btn {
                margin: 0 $g-sm;
            }
        }
    }
}