// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


.modal-open {
    // Kill the scroll on the body
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Container that the modal scrolls within
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;
    outline: 0;
    overflow: hidden;

    z-index: $zindex-modal;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: $modal-dialog-margin;
    pointer-events: none;

    .modal.fade & {
        @include transition($modal-transition);
        transform: translate(0, -25%);
    }
    .modal.show & {
        transform: translate(0, 0);
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (#{$modal-dialog-margin} * 2));

    // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
    &::before {
        display: block; // IE10
        height: calc(100vh - (#{$modal-dialog-margin} * 2));
        content: "";
    }
}

// Actual modal
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    pointer-events: auto;

    background: url('../img/bg-modal.png'),
                $modal-content-bg;
    background-size: cover;
    background-clip: padding-box;

    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    outline: 0;

    padding: $g-sm;
    text-align: center;

    .title {
        @extend .u-fz-36;
        line-height: 1.3;
        max-width: 85%;
        margin: 0 auto;
        
        &.title--sm {
            @extend .u-fz-24;
        }
        
        @include media-breakpoint-up(md) {
            max-width: 90%;
        } 
    }
    
    .subtitle {
        @extend .u-fz-18;
        max-width: 90%;
        margin: $o-xs auto 0;
    }

    .c-form {
        margin-top: $g-sm;
    }

    .btn-primary {
        @extend .u-fz-18;
        width: 100%;

        b {
            margin: 0 auto;
        }
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    background-color: $modal-backdrop-bg;

    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    display: flex;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: $modal-header-padding;
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    @include border-top-radius($modal-content-border-radius);

    .close {
        padding: $modal-header-padding;
        // auto on the left force icon to the right even when there is no .modal-title
        margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
    }
}

// Title text within header
.modal-title {
    margin-bottom: 0;
    line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when should there be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
    display: flex;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: $modal-inner-padding;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;

    // Easily place margin between footer elements
    > :not(:first-child) { margin-left: .25rem; }
    > :not(:last-child) { margin-right: .25rem; }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));

        &::before {
            height: calc(100vh - (#{$modal-dialog-margin-y-sm-up} * 2));
        }

    }

    .modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
        padding: $g;

    }

    .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
    .modal-lg { max-width: $modal-lg; }
    .modal-content {
        .c-form {
            margin-top: $g;
        }
    }
}
